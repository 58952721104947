import { Guid } from "@/Utils/Guid/Guid";

export abstract class Singleton extends Guid {
	private static _instances: WeakMap<any, Singleton>;

	public static init(): void {
		this._instances = new WeakMap<Singleton>();
	}

	private static _initIfNeeded(): void {
		if (this._instances == null) {
			this.init();
		}
	}

	public static i<T extends Singleton>(): T { return Singleton._makeOrGetInstance(this) as T; }

	protected static _makeOrGetInstance<T extends Singleton>(cls: any) {
		if (!Singleton._instances.has(cls)) {
			Singleton._instances.set(cls, new cls());
		}
		let instance: T = Singleton._instances.get(cls) as T;
		return instance;
	}
}
