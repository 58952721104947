
export type TBoolifyable = boolean|any;

export class EzError { // to be kept synced with PHP's Errors::ERR_*
	// log warning to console in DEBUG_MODE. logs nothing otherwise (but still returns false if the assertion fails).
	public static debuggingAssert(sanityConditionResult: TBoolifyable, message: string|null = null): boolean {
		return EzError._internalAssert(sanityConditionResult, message, false, true);
	}

	// crashes in DEBUG_MODE (with alert()), but only issues a console warning if not in DEBUG_MODE.
	public static softAssert(sanityConditionResult: TBoolifyable, message: string|null = null): boolean {
		return EzError._internalAssert(sanityConditionResult, message, true, false);
	}

	// always crashes, but in DEBUG_MODE also alert()s.
	public static assert(sanityConditionResult: TBoolifyable, message: string|null = null): void {
		EzError._internalAssert(sanityConditionResult, message, false, false);
	}

	private static _internalAssert(sanityConditionResult: TBoolifyable, message: string|null, soft: boolean, onlyForDebugging: boolean): boolean {
		if (sanityConditionResult) {
			return true;
		}

		// if (TestingMediator.inTestingMode) {
		// 	// when testing - throw instead of warn, to make sure it's caught, and also to allow using `expect().toThrowError()`.
		// }

		if (message == null) {
			message = "(not provided)";
		}
//debugger;
		let text = `Sanity check failed. Message: ${message}`;
		//let inDebugMode = EzError._debugMode;
		let inDebugMode = false;
		// if (TestingMediator.inTestingMode) {
		// 	// when testing - throw instead of warn, to make sure it's caught, and also to allow using `expect().toThrowError()`.
		// 	inDebugMode = true;
		// }

		if (inDebugMode) {
			if (onlyForDebugging) {
				// softer than an actual error
				console.warn(text);
				return false;
			}

			// if (!TestingMediator.inTestingMode) {
			alert(text);
			// }

			throw new Error(text);
		}

		if (onlyForDebugging) {
			// just for debugging. not showing anything in the console when not in debug mode
			return false;
		}

		if (soft) {
			console.warn(text);
			return false;
		}

		throw new Error(text);
	}
}
