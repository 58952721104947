
export class EZ {
	public static isDefined(v: any): boolean {
		return (typeof v !== "undefined");
	}

	public static isObject(value: any): value is Object {
		// http://jsperf.com/isobject4
		return (value !== null && typeof value === "object");
	}

	public static isString(v: any): v is string {
		if (v == null) return false;
		return (typeof v == "string");
	}

	public static isBool(v: any): v is boolean {
		return (v === true || v === false);
	}

	public static isNumber(v: any): v is number {
		if (v === null || v === "") return false;
		if (v * 1 == v) return true;
		return false;
	}

	public static isEmpty(v: any): boolean {
		if (v == null || v === "" || (Array.isArray(v) && v.length == 0)) return true;
		if (EZ.isObject(v) && Object.keys(v).length == 0) return true;
		return false;
	}

	public static stringify(v: number|boolean|any|null): string|null {
		if (!EZ.isDefined(v)) return v;
		if (v === null) return null;
		if (!EZ.isBool(v)) return v + "";
		if (v) return "true";
		return "false";
	}

	public static replaceAll(haystack: string, search: string, replace: any): string {
		return haystack.split(search).join(replace);
	};

	public static numberify(s: any, castNaNToZero = true): number|null {
		if (s == null) return s ?? null;
		if (s === true) return 1;
		if (s === false) return 0;
		let n = s * 1;
		if (isNaN(n) && castNaNToZero) return 0;
		return n;
	}

	public static boolify(a: any): boolean|null {
		if (!EZ.isDefined(a) || a === null) return null;
		if (typeof a == "string" && (a.toLowerCase() == "false" || a == "0")) return false;
		if (a) return true;
		return false;
		//return (a == 1);
	}

	public static round(number: number, precision: number): number {
		// from here: https://stackoverflow.com/questions/1458633/how-to-deal-with-floating-point-number-precision-in-javascript
		let factor = Math.pow(10, precision);
		return Math.round(number * factor) / factor;
	}
}
