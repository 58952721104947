import { ServiceBase } from "@/Service/ServiceBase";

export class SvcStorage extends ServiceBase {
	private _store(key: string, item: any): void {
		if (item == null) {
			localStorage.setItem(key, item);
		}

		localStorage.setItem(key, JSON.stringify(item));
	}

	private _retrieve<T>(key: string): T|null {
		let json = localStorage.getItem(key)
		if (json == null) {
			return null;
		}

		return JSON.parse(json);
	}

	private _remove(key: string): void {
		localStorage.removeItem(key);
	}

	public storeSession(args: { sessionIdentifier: string, workspaceName: string }): void {
		this._store("sessionIdentifier", args.sessionIdentifier);
		this._store("workspaceName", args.workspaceName);
	}

	public clearSession(): void {
		this._remove("sessionIdentifier");
		this._remove("workspaceName");
	}

	public getSessionIdentifier(): string|null {
		return this._retrieve("sessionIdentifier");
	}

	public getWorkspaceName(): string|null {
		return this._retrieve("workspaceName");
	}
}
