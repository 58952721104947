import { EZ } from "@/Utils/EZ/EZ";

export abstract class Utils {
	public static getQueryStringParameter(name: string, search?: string, caseInsensitive: boolean = false): string|null {
		name = name.replace(/\[/, "\\\[").replace(/\]/, "\\\]");
		let regexS: string = "[\\?&]" + name + "=([^&#]*)";
		let flags: string|undefined = undefined;
		if (caseInsensitive) {
			flags = "i";
		}
		let regex = new RegExp(regexS, flags as any);
		if (!EZ.isDefined(search) || search == null) {
			search = window.location.search;
		}
		let results: RegExpExecArray|null = regex.exec(search);
		if (results == null) {
			return null;
		}
		else return decodeURIComponent(results[1].replace(/\+/g, " "));
	}

	public static parseDomain(url: string): string {
		// http://stackoverflow.com/questions/8498592/extract-root-domain-name-from-string

		let domain;
		// find & remove protocol (http, ftp, etc.) and get domain
		if (url.indexOf("://") > -1) {
			domain = url.split("/")[2];
		}
		else {
			domain = url.split("/")[0];
		}

		// find & remove port number
		domain = domain.split(":")[0];

		if (EZ.isEmpty(domain)) domain = "";

		return domain;
	}

	public static isLocal(): boolean {
		let domain = Utils.parseDomain(window.location.host);
return false;
		return (domain.startsWith("u."));
	}

	public static validateEmail(email: string|null): boolean {
		if (email == null) {
			return false;
		}

		const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return emailRegex.test(email);
	}

	public static validatePassword(password: string|null, args: {
		minLength: number,
		maxLength: number,
	}): boolean {
		if (password == null) {
			return false;
		}

		if (password.length < args.minLength) {
			return false;
		}

		if (password.length > args.maxLength) {
			return false;
		}

		return true;
	}
}
