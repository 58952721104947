import Vue from 'vue';
import App from './App.vue';
import router from './router';
//import LottieVuePlayer from '@lottiefiles/vue-lottie-player'

import Component from 'vue-class-component';

// Component.registerHooks([
// 	"metaInfo",
// ]);

//Vue.use(LottieVuePlayer)
Vue.config.productionTip = false;

// https://github.com/nuxt/vue-meta/issues/144#issuecomment-334190061
declare module "vue/types/options" {
    interface ComponentOptions<V extends Vue> {
        metaInfo?: any;
    }
}

new (Vue as any)({
	render: h => h(App),
	router: router as any,
}).$mount('#app');
