
import { Component } from "vue-property-decorator";
import { Vue } from "vue-property-decorator";
import { IAccountInfo } from "@/Service/Account/IAccountInfo/IAccountInfo";
import { SvcStorage } from "@/Service/Storage/SvcStorage";
import { WindowUtils } from "@/Utils/WindowUtils/WindowUtils";
import { Singleton } from "@/FW/Singleton/Singleton";

@Component({
	props: {
		accountInfo: Object,
		withLogin: Boolean,
	},
})
export default class Navigation extends Vue {
	private get _svcStorage(): SvcStorage { return SvcStorage.i(); }
	
	public isLoggedIn: boolean = false;

	// private get _accountInfo(): IAccountInfo|null {
	// 	return this["accountInfo"] as IAccountInfo|null;
	// }
	//
	// private get _withLogin(): boolean {
	// 	return this["withLogin"];
	// }

	// public get showLoginLink(): boolean {
	// 	return this._withLogin && (this._accountInfo == null);
	// }
	//
	// public get showLogoutLink(): boolean {
	// 	return (this._accountInfo != null);
	// }
	
	public logout(): void {
		this._svcStorage.clearSession();
		WindowUtils.redirect("/");
	}
	
	public mounted(): void {
		Singleton.init();
		this._initLoginState();
	}

	private _initLoginState(): void {
		let sessionIdentifier = this._svcStorage.getSessionIdentifier();
		this.isLoggedIn = (sessionIdentifier != null);
	}
}
