
import { Component, Vue } from "vue-property-decorator";
import NavBar from "@/views/Components/NavBar.vue";

@Component({
	components: {
		NavBar,
	},
	metaInfo: {
		title: "Member - Boboto",
		meta: [
			{
				name: "description",
				content:
					"Boboto is the best way to leverage cutting edge AI chatbots on Slack!",
			},
			{
				property: "og:title",
				content: "Terms and Conditions - Boboto",
			},
			{
				property: "og:description",
				content: "Boboto is the best way to leverage cutting edge AI chatbots on Slack!",
			},
		],
	},
})

export default class Tos extends Vue {

}
