
import { Component, Vue } from "vue-property-decorator";
import { SvcApi } from "@/Service/Api/SvcApi";
import { IAccountInfo } from "@/Service/Account/IAccountInfo/IAccountInfo";
import { EZ } from "@/Utils/EZ/EZ";
import Vuetify from "vuetify";
import { SvcSubscription } from "@/Service/Subscription/SvcSubscription";
import { EPlanType } from "@/Service/Subscription/SvcSubscription";
import { ESubscriptionType } from "@/Service/Subscription/Type/ESubscriptionType";

enum EState {
	prompt = "prompt",
	confirmUpdate = "confirmUpdate",
	updating = "updating",
	updated = "updated",
	error = "error",
}

Vue.use(Vuetify);

@Component({
	props: {
		accountInfo: Object,
	},
})
export default class UpdateSubscriptionBox extends Vue {
	private get _svcApi(): SvcApi { return SvcApi.i(); }
	private get _svcSubscription(): SvcSubscription { return SvcSubscription.i(); }
	
	public state: EState = EState.prompt;
	public numUsers: number = 0;
	public isReady: boolean = false;

	private _wasChanged: boolean = false;
	
	private get _accountInfo(): IAccountInfo {
		return this["accountInfo"] as IAccountInfo;
	}
	
	public get isStatePrompt(): boolean {
		return (this.state == EState.prompt);
	}
	
	public get isStateConfirmUpdate(): boolean {
		return (this.state == EState.confirmUpdate);
	}
	
	public get isStateUpdating(): boolean {
		return (this.state == EState.updating);
	}
	
	public get isStateUpdated(): boolean {
		return (this.state == EState.updated);
	}
	
	public get isStateError(): boolean {
		return (this.state == EState.error);
	}
	
	public get isPlanTypeSelfTokens(): boolean {
		return (this._accountInfo.subscriptionType == ESubscriptionType.selfTokens);
	}
	
	public get isPlanTypeBobotokens(): boolean {
		return (this._accountInfo.subscriptionType == ESubscriptionType.bobotokens);
	}
	
	public get slackWorkspaceIdentifier(): string {
		return this._accountInfo.slackWorkspaceIdentifier;
	}q
	public async updateSubscription(): Promise<void> {
		this._setStateToConfirmUpdate()
	}
	
	public async reallyUpdateSubscription(): Promise<void> {
		await this._updateSubscription();
	}
	
	public closeUpdateSubscriptionBox(): void {
		this.$emit("close", this._wasChanged);
	}
	
	private async _updateSubscription(): Promise<void> {
		this._setStateToUpdating();
		if (await this._svcApi.revisePaypalSubscription(this.numUsers)) {
			this._wasChanged = true;
			this._setStateToUpdated();
			return;
		}
		
		this._setStateToError();
	}

	private setStateToPrompt(): void {
		this.state = EState.prompt;
	}

	private _setStateToConfirmUpdate(): void {
		this.state = EState.confirmUpdate;
	}
	
	private _setStateToUpdating(): void {
		this.state = EState.updating;
	}
	
	private _setStateToUpdated(): void {
		this.state = EState.updated;
	}
	
	private _setStateToError(): void {
		this.state = EState.error;
	}

	public get formattedNumUsers(): string {
		if (this.numUsers == 1000) {
			return "Unlimited";
		}
		return EZ.stringify(this.numUsers)!;
	}

	public get formattedCurrentNumUsers(): string {
		let numUsers = this._accountInfo.maxMonthlyUniqueUsers;
		if (numUsers == 1000) {
			return "Unlimited";
		}
		return EZ.stringify(numUsers)!;
	}
	
	private _getPlanType(): EPlanType {
		return (this.isPlanTypeBobotokens ? EPlanType.bobotokensPlan : EPlanType.selfTokensPlan);
	}

	public get formattedTotalPrice(): string {
		let planType = this._getPlanType();
		let pricePerUser = this._svcSubscription.getPricePerUser(planType);
		let price = EZ.round(pricePerUser * this.numUsers, 1);
		return `USD $${price}`;
	}

	public get formattedCurrentTotalPrice(): string {
		let planType = this._getPlanType();
		let pricePerUser = this._svcSubscription.getPricePerUser(planType);
		let numUsers = this._getCurNumUsers();
		let price = EZ.round(pricePerUser * numUsers, 1);
		return `USD $${price}`;
	}

	public get numMonthlyBobotokens(): number {
		let numBobotokensPerUser = this._svcSubscription.getNumBobotokensPerUser(this._getPlanType());
		return numBobotokensPerUser * this.numUsers;
	}
	
	private _getCurNumUsers(): number {
		return this._accountInfo.maxMonthlyUniqueUsers;
	}

	public get currentNumMonthlyBobotokens(): number {
		let numBobotokensPerUser = this._svcSubscription.getNumBobotokensPerUser(this._getPlanType());
		let numUsers = this._getCurNumUsers();
		return numBobotokensPerUser * numUsers;
	}
	
	public mounted(): void {
		this.numUsers = this._accountInfo.maxMonthlyUniqueUsers;
		this.isReady = true;
	}
}
