import { render, staticRenderFns } from "./ActivePlanScreen.vue?vue&type=template&id=5bd94f7a&scoped=true&xmlns=http%3A%2F%2Fwww.w3.org%2F1999%2Fhtml&"
import script from "./ActivePlanScreen.vue?vue&type=script&lang=ts&"
export * from "./ActivePlanScreen.vue?vue&type=script&lang=ts&"
import style0 from "./ActivePlanScreen.vue?vue&type=style&index=0&id=5bd94f7a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bd94f7a",
  null
  
)

export default component.exports