
import { Component, Vue } from "vue-property-decorator";
import { SvcVendorPaypal } from "@/Service/Vendors/Paypal/SvcVendorPaypal";
import { OnApproveData } from "@paypal/paypal-js/types/components/buttons";
import { OnApproveActions } from "@paypal/paypal-js/types/components/buttons";
import { OnCancelledActions } from "@paypal/paypal-js/types/components/buttons";
import { Cfg } from "@/Cfg/Cfg";
import { IPaypalCfg } from "@/Cfg/Cfg";
import Vuetify from "vuetify";
import { Utils } from "@/Utils/Utils";
import { EZ } from "@/Utils/EZ/EZ";
import { SvcApi } from "@/Service/Api/SvcApi";
import { IAccountInfo } from "@/Service/Account/IAccountInfo/IAccountInfo";
import { EPlanType } from "@/Service/Subscription/SvcSubscription";
import { SvcSubscription } from "@/Service/Subscription/SvcSubscription";
// import Vuetify from 'vuetify'

Vue.use(Vuetify);

enum EState {
	choosePlanType = "choosePlanType",
	pendingPayment = "pendingPayment",
	finalizingSubscription = "finalizingSubscription",
}

@Component({
	components: {
		//AppNavbar,
		// Slider,
	},
	props: {
		accountInfo: Object,
	},
})
export default class TrialScreen extends Vue {
	private get _svcApi(): SvcApi { return SvcApi.i(); }
	private get _svcSubscription(): SvcSubscription { return SvcSubscription.i(); }
	private get _svcVendorPaypal(): SvcVendorPaypal { return SvcVendorPaypal.i(); }
	
	public numUsers: number = 5;
	public errorMessage: string|null = null;
	public state: EState = EState.choosePlanType;
	public planType: EPlanType|null = null;
	
	private get _accountInfo(): IAccountInfo {
		return this["accountInfo"] as IAccountInfo;
	}
	
	public get isStateChoosePlanType(): boolean {
		return (this.state == EState.choosePlanType);
	}
	
	public get isStatePendingPayment(): boolean {
		return (this.state == EState.pendingPayment);
	}
	
	public get isStateFinalizingSubscription(): boolean {
		return (this.state == EState.finalizingSubscription);
	}
	
	public get isPlanTypeSelfTokens(): boolean {
		return (this.planType == EPlanType.selfTokensPlan);
	}
	
	public get isPlanTypeBobotokens(): boolean {
		return (this.planType == EPlanType.bobotokensPlan);
	}
	
	public get numMonthlyBobotokens(): number {
		let numBobotokensPerUser = this._svcSubscription.getNumBobotokensPerUser(this.planType!);
		return numBobotokensPerUser * this.numUsers;
	}
	
	public get hasLingeringSubscription(): boolean {
		return this._accountInfo.hasLingeringSubscription;
	}
	
	public get formattedDateLingeringSubscriptionEnd(): string {
		return this._accountInfo.datePlanEnd!;
	}
	
	public onPlanChanged(): void {
		let planIdentifier = this._getEffectivePlanIdentifier();
		this._svcVendorPaypal.setPlanIdentifier(planIdentifier);
		this._setStateToPendingPayment();
	}
	
	private _getPaypalContainerElement(): HTMLElement {
		return this.$refs["paypalContainer"] as HTMLElement;
	}
	
	public async mounted(): Promise<void> {
		await this._initPaypal();
// await this._finalizePaypalSubscription("abc");
	}
	
	public get formattedTotalPrice(): string {
		//let pricePerUser = this._getPricePerUser();
		let pricePerUser = this._svcSubscription.getPricePerUser(this.planType!);
		let price = EZ.round(pricePerUser * this.numUsers, 1);
		return `USD $${price}`;
	}
	
	// private _getPricePerUser(): number {
	// 	return (this.planType == EPlanType.bobotokensPlan ? 5 : 2);
	// }
	
	public get formattedNumUsers(): string {
		if (this.numUsers == 1000) {
			return "Unlimited";
		}
		return EZ.stringify(this.numUsers)!;
	}
	
	private async _initPaypal(): Promise<void> {
		let elContainer = this._getPaypalContainerElement();
		let planIdentifier = this._getEffectivePlanIdentifier();
		await this._svcVendorPaypal.initButtons({
			elContainer,
			funcGetQuantity: (): number => {
				return this.numUsers;
			},
			funcOnApprove: async (data: OnApproveData, actions: OnApproveActions): Promise<void> => {
				//console.log("in funcOnApprove. data: ", data, "actions: ", actions);
				let subscriptionIdentifier = data.subscriptionID;
				await this._finalizePaypalSubscription(subscriptionIdentifier!);
			},
			funcOnCancel: (data: Record<string, unknown>, actions: OnCancelledActions): void => {
				//console.log("in funcOnCancel. data: ", data, "actions: ", actions);
				this._setStateToPendingPayment();
			},
			funcOnError: (err: Record<string, unknown>): void => {
				console.log("in funcOnError. err: ", err);
				this.errorMessage = `An error has occurred: ${JSON.stringify(err)}`;
				this._setStateToPendingPayment();
			},
			funcOnClick: async (data: Record<string, unknown>): Promise<void> => {
			
			},
			planIdentifier: planIdentifier,
		});
	}
	
	//private _getPaypalCfg(forLive: boolean = false): IPaypalCfg {
// 	private _getPaypalCfg(): IPaypalCfg {
// 		let isLocal = Utils.isLocal();
// isLocal = false;
// 		return (isLocal ? Cfg.paypal.sandbox : Cfg.paypal.live);
// 	}
	
	private _getEffectivePlanIdentifier(): string {
		// let cfg = this._getPaypalCfg();
		let cfg = this._svcVendorPaypal.getPaypalCfg();
		return (this.isPlanTypeBobotokens ? cfg.paypalBobotokensPlanIdentifier : cfg.paypalSelfTokensPlanIdentifier);
	}
	
	private async _finalizePaypalSubscription(subscriptionIdentifier: string): Promise<void> {
		this._setStateToFinalizingSubscription();
		let response = await this._svcApi.finalizePaypalSubscription(subscriptionIdentifier);
		if (response == null) {
			let workspaceIdentifier = this._accountInfo.slackWorkspaceIdentifier;
			this.errorMessage = `Failed to finalize subscription. Please contact support@boboto.chat, and provide the Subscription ID (${subscriptionIdentifier}) and your Slack workspace ID (${workspaceIdentifier}).`;
			this._setStateToPendingPayment();
			return;
		}
		
		this.$emit("subscribed");
	}
	
	private _setStateToChoosePlanType(): void {
		this.state = EState.choosePlanType;
	}
	
	private _setStateToPendingPayment(): void {
		this.state = EState.pendingPayment;
	}
	
	private _setStateToFinalizingSubscription(): void {
		this.state = EState.finalizingSubscription;
	}
}
