
import { Component, Prop, Vue } from "vue-property-decorator";
import AppNavbar from '../components/navbar.vue';
import { Tick } from "@/Utils/Tick/Tick";
import { NoConcurrency } from "@/Utils/NoConcurrency/NoConcurrency";
import axios from "axios";
import { WindowUtils } from "@/Utils/WindowUtils/WindowUtils";
import { SvcApi } from "@/Service/Api/SvcApi";
import { SvcStorage } from "@/Service/Storage/SvcStorage";
import { Singleton } from "@/FW/Singleton/Singleton";
import NavBar from "@/views/Components/NavBar.vue";
import { SvcVendorSlack } from "@/Service/Vendors/Slack/SvcVendorSlack";
import AddToSlack from "@/views/Components/AddToSlack.vue";

@Component({
	components: {
		NavBar,
		AddToSlack,
	},
	metaInfo: {
		title: "Login - Boboto",
		meta: [
			{
				name: "description",
				content:
					"Boboto is the best way to leverage cutting edge AI chatbots on Slack!",
			},
			{
				property: "og:title",
				content: "Login - Boboto",
			},
			{
				property: "og:description",
				content: "Boboto is the best way to leverage cutting edge AI chatbots on Slack!",
			},
		],
	},
})
export default class Login extends Vue {
	noconLogin: NoConcurrency = new NoConcurrency();
	
	public isReady: boolean = false;
	public email: string|null = null;
	public password: string|null = null;
	public errorMessage: string|null = null;
	
	private get _svcApi(): SvcApi { return SvcApi.i(); }
	private get _svcStorage(): SvcStorage { return SvcStorage.i(); }
	private get _svcVendorSlack(): SvcVendorSlack { return SvcVendorSlack.i(); }
	
	private _bootstrap(): void {
		Singleton.init();
	}
	
	public login(): void {
		this.noconLogin.go(async () => {
			// let { data, status } = await axios.post("https://api.boboto.chat/Prod/dispatcher/?route=/login", {
			// 	email: "blah",
			// 	password: "blah",
			// });
			//let { data, status } = await axios.get("https://api.boboto.chat/Prod/dispatcher/?route=/login");
			// let { data, status } = await axios.get("https://api.boboto.chat/Prod/dispatcher/?route=/hello");
			// let { data, status } = await axios.post("https://api.boboto.chat/Prod/dispatcher/?route=/login", {
			// //let { data, status } = await axios.post("https://api.boboto.chat/Prod/dispatcher/?route=/hello", {
			// 	email: this.email,
			// 	password: this.password,
			// });
			// console.log("status: ", status, "data: ", data);
			
			this.errorMessage = null;
			let response = await this._svcApi.loginWithCredentials(this.email!, this.password!);
			if (!response.success) {
				//alert(`An error has occurred: ${response.errorMessage}`);
				this.errorMessage = response.errorMessage ?? null;
				return;
			}
			
			this._storeSession({
				sessionIdentifier: response.data!.sessionIdentifier!,
				workspaceName: response.data!.slackWorkspaceName!,
			});
			
			WindowUtils.redirect("/member");
		});
	}
	
	private _storeSession(args: { sessionIdentifier: string, workspaceName: string }): void {
		this._svcStorage.storeSession(args);
	}
	
	public get addToSlackUrl(): string {
		// let base = "https://slack.com/oauth/v2/authorize?client_id=4607701396997.4604474765062&scope=app_mentions:read,chat:write,im:history&user_scope=";
		// let url = `${base}&state=development`;
		// return url;
		return this._svcVendorSlack.getAddToSlackUrl();
	}
	
	public mounted(): void {
		this._bootstrap();
		this.isReady = true;
	}
// https://zxn0eimang.execute-api.us-east-1.amazonaws.com/Prod/dispatcher/?route=/slackOAuth&code=2210001726997.4666052989479.d6af02a26a27381d0f52981a6277f0cd7c2c59504cc31b407e66cc12c3256b63&state=12345
}
