import { ServiceBase } from "@/Service/ServiceBase";
import { Utils } from "@/Utils/Utils";

export class SvcVendorSlack extends ServiceBase {
	public getAddToSlackUrl(): string {
		let base = "https://slack.com/oauth/v2/authorize?client_id=4607701396997.4604474765062&scope=app_mentions:read,chat:write,im:history,files:write&user_scope=";
		let url = base;
		if (Utils.isLocal()) {
			url += "&state=development";
		}
		return url;
	}
}
