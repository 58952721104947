
export class NoConcurrency {
	private _isRunning: boolean = false;

	public async go(func: () => Promise<void>): Promise<boolean> {
		if (this._isRunning) {
			return false;
		}

		this._isRunning = true;
		try {
			await func();
		}
		finally {
			this._isRunning = false;
		}

		return true;
	}
}
