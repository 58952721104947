import { ServiceBase } from "@/Service/ServiceBase";

export enum EPlanType {
	selfTokensPlan = "selfTokensPlan",
	bobotokensPlan = "bobotokensPlan",
}

export class SvcSubscription extends ServiceBase {
	public getPricePerUser(planType: EPlanType): number {
		return (planType == EPlanType.bobotokensPlan ? 5 : 2);
	}

	public getNumBobotokensPerUser(planType: EPlanType): number {
		return (planType == EPlanType.bobotokensPlan ? 3000 : 0);
	}
}
