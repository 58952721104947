
// noinspection SpellCheckingInspection

export interface IPaypalCfg {
	paypalClientIdentifier: string;
	paypalSelfTokensPlanIdentifier: string;
	paypalBobotokensPlanIdentifier: string;
}

export abstract class Cfg {
	public static readonly paypal = {
		sandbox: {
			paypalClientIdentifier: "AdMw70cOG-82EMJTeeAAMRawOXMxv2JyNgjfSR0yx2ifoIXoONL87-osqwYnd8eeIwF1lcTfRYd4NVOb",
			paypalSelfTokensPlanIdentifier: "P-41N16311LA6761314MPKRQTQ",
			paypalBobotokensPlanIdentifier: "P-1B9147634G148894TMPK4FLY",
		},
		live: {
			//paypalClientIdentifier: "Af5kxFjmCs_21898XVhFZXQlLVYnWIHNtloTLFdlePovucnrey3f7euAB8yyrlUoI1PsEMkkZSFP_pR",
			paypalClientIdentifier: "Af5kxFjmCs_21898XVhFZXQlLVYnWIHNtloTLFdlePovucnrey3f7euAB8yyrlUoI1PsEMkkZSFP_pR-",
			paypalSelfTokensPlanIdentifier: "P-6GB451724H817293KMPJRYAQ",
			paypalBobotokensPlanIdentifier: "P-2YU793605K0930829MPK4HUA",
		},
	};
}
