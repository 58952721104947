import { ServiceBase } from "@/Service/ServiceBase";
import axios from "axios";

export class SvcAjax extends ServiceBase {
	public async get<T>(url: string): Promise<T> {
		let { data, status } = await axios.get(url);
		if (status != 200) {
			throw new Error(`Invalid response from GET [${url}]. HTTP status: ${status}, data: ${JSON.stringify(data)}`);
		}

		return data as T;
	}

	public async post<T>(url: string, requestData = { }): Promise<T> {
		let { data, status } = await axios.post(url, requestData);
		if (status != 200) {
			throw new Error(`Invalid response from POST [${url}]. HTTP status: ${status}, data: ${JSON.stringify(data)}`);
		}

		return data as T;
	}
}
