
import { Component, Vue } from "vue-property-decorator";
import { SvcApi } from "@/Service/Api/SvcApi";
import { IAccountInfo } from "@/Service/Account/IAccountInfo/IAccountInfo";
import { EZ } from "@/Utils/EZ/EZ";
import CancelBox from "@/views/Member/ActivePlanScreen/CancelBox/CancelBox.vue";
import { WindowUtils } from "@/Utils/WindowUtils/WindowUtils";
import SelfOpenAIApiKeyBox from "@/views/Member/ActivePlanScreen/SelfOpenAIApiKeyBox/SelfOpenAIApiKeyBox.vue";
import UpdateSubscriptionBox from "@/views/Member/ActivePlanScreen/UpdateSubscriptionBox/UpdateSubscriptionBox.vue";

enum EState {
	primaryManagement = "primaryManagement",
	setOpenAIApiKey = "setOpenAIApiKey",
	changeMonthlyUsers = "changeMonthlyUsers",
	cancelSubscription = "cancelSubscription",
}


@Component({
	components: {
		UpdateSubscriptionBox,
		SelfOpenAIApiKeyBox,
		CancelBox
		//AppNavbar,
		// Slider,
	},
	props: {
		accountInfo: Object,
	},
})
export default class ActivePlanScreen extends Vue {
	private get _svcApi(): SvcApi { return SvcApi.i(); }
	
	public state: EState = EState.primaryManagement;
	// public newOpenAIApiKey: string = "";
	public isReady: boolean = false;

	private get _accountInfo(): IAccountInfo {
		return this["accountInfo"] as IAccountInfo;
	}
	
	public get isBobotokensPlan(): boolean {
		return (this._accountInfo.subscriptionMonthlyBobotokens > 0);
	}
	
	public get formattedPlanType(): string {
		return (this.isBobotokensPlan ? "Bobotokens Plan" : "Self-tokens Plan");
	}
	
	public get numMonthlyUniqueUsers(): number {
		return this._accountInfo.maxMonthlyUniqueUsers;
	}
	
	public get numBobotokensPerMonth(): number {
		return this._accountInfo.subscriptionMonthlyBobotokens;
	}
	
	public get formattedNumRemainingBobotokens(): string {
		return EZ.stringify(EZ.round(this._accountInfo.bobotokens, 5))!;
	}
	
	public get shouldShowPrimaryManagement(): boolean {
		return (this.state == EState.primaryManagement);
	}
	
	public get shouldShowSetOpenAIApiKey(): boolean {
		return (this.state == EState.setOpenAIApiKey);
	}
	
	public get shouldShowChangeMonthlyUsers(): boolean {
		return (this.state == EState.changeMonthlyUsers);
	}
	
	public get shouldShowCancelSubscription(): boolean {
		return (this.state == EState.cancelSubscription);
	}
	
	// public get isUsingOwnOpenAIAccount(): boolean {
	// 	return this._accountInfo.isUsingOwnOpenAIAccount;
	// }
	
	// public get openAIApiKeySuffix(): string {
	// 	return this._accountInfo.openAIApiKeySuffix!;
	// }
	
	public setOpenAIApiKey(): void {
		this.state = EState.setOpenAIApiKey;
	}
	
	public changeMonthlyUsers(): void {
		this.state = EState.changeMonthlyUsers;
	}
	
	public cancelSubscription(): void {
		this.state = EState.cancelSubscription;
	}
	
	// public saveNewOpenAIApiKey(): void {
	// 	console.log("saveNewOpenAIApiKey...");
	// }
	
	public closeSetOpenAIApiKeyBox(wasChanged: boolean): void {
		if (wasChanged) {
			this.refresh();
		}
		else {
			this._setStateToPrimaryManagement();
		}
	}
	
	public closeUpdateSubscriptionBox(wasChanged: boolean): void {
		if (wasChanged) {
			this.refresh();
		}
		else {
			this._setStateToPrimaryManagement();
		}
	}
	
	public closeCancelSubscription(): void {
		this._setStateToPrimaryManagement();
	}

	
	private _setStateToPrimaryManagement(): void {
		this.state = EState.primaryManagement;
	}
	
	public refresh(): void {
		this.isReady = false;
		WindowUtils.refresh();
	}
	
	public mounted(): void {
		this.isReady = true;
	}
}
