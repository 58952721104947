import { Q } from "@/Utils/Q/Q";
import { Utils } from "@/Utils/Utils";

export enum EUrlOpenTarget {
	_blank = "_blank",
}

export class WindowUtils {
	private static readonly CHILD_WINDOW_OPENER_CHECK_INTERVAL_MS = 250;
	private static readonly CHILD_WINDOW_OPENER_CHECK_TIMEOUT_MS = 300;

	// keep track of child window during redirect from us -> third party -> us and set opener property
	// because cross-origin policy sets child's window.opener to 'null' when redirecting to different domain
	public static tryKeepChildWindowOpener(childWindow: Window): void {
		let wasNavigatedAway = false;
		let interval = setInterval(() => {
			try {
				// child window was closed
				if (childWindow.window == null) {
					clearInterval(interval);
					return;
				}

				childWindow.opener = window;
			} catch (e) {
				// when navigating to another domain window.opener throws an error, due to cross-origin policy
				// so if we catch an error, we now at third-party domain
				wasNavigatedAway = true;
				return;
			}

			// we passed catch block which means we returned back from third-party window
			if (wasNavigatedAway) {
				clearInterval(interval);
			}
		}, WindowUtils.CHILD_WINDOW_OPENER_CHECK_INTERVAL_MS);
	}

	public static async tryGetKeptWindowOpener(w: Window): Promise<Window> {
		let defer = Q.deferAsync<Window>();
		setTimeout(() => {
			w.opener == null ? defer.reject() : defer.resolve(w.opener);
		}, WindowUtils.CHILD_WINDOW_OPENER_CHECK_TIMEOUT_MS);

		return defer.promise;
	}

	public static redirect(url: string): void {
		window.location.href = url;
	}

	public static openUrl(url: string, target?: EUrlOpenTarget|string): Window {
		return window.open(url, target as any) as Window;
	}

	public static refresh(): void {
		window.location.reload();
	}
}
