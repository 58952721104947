
import { Component, Vue } from "vue-property-decorator";
import { SvcApi } from "@/Service/Api/SvcApi";
import { IAccountInfo } from "@/Service/Account/IAccountInfo/IAccountInfo";
import { EZ } from "@/Utils/EZ/EZ";
import { WindowUtils } from "@/Utils/WindowUtils/WindowUtils";

enum EState {
	prompt = "prompt",
	cancelling = "cancelling",
	cancelled = "cancelled",
	error = "error",
}

@Component({
	props: {
		accountInfo: Object,
	},
})
export default class CancelBox extends Vue {
	private get _svcApi(): SvcApi { return SvcApi.i(); }
	
	public state: EState = EState.prompt;
	
	private get _accountInfo(): IAccountInfo {
		return this["accountInfo"] as IAccountInfo;
	}
	
	public get isStatePrompt(): boolean {
		return (this.state == EState.prompt);
	}
	
	public get isStateCancelling(): boolean {
		return (this.state == EState.cancelling);
	}
	
	public get isStateCancelled(): boolean {
		return (this.state == EState.cancelled);
	}
	
	public get isStateError(): boolean {
		return (this.state == EState.error);
	}
	
	public get slackWorkspaceIdentifier(): string {
		return this._accountInfo.slackWorkspaceIdentifier;
	}
	
	
	public async reallyCancelSubscription(): Promise<void> {
		this._setStateToCancelling();
		if (await this._svcApi.cancelPaypalSubscription()) {
			this._setStateToCancelled();
			return;
		}
		
		this._setStateToError();
	}
	
	public closeCancelSubscription(): void {
		this.$emit("close");
	}
	
	public closeCancelSubscriptionAndRefresh(): void {
		this.closeCancelSubscription();
		this.$emit("cancelled");
	}
	
	private _setStateToPrompt(): void {
		this.state = EState.prompt;
	}
	
	private _setStateToCancelling(): void {
		this.state = EState.cancelling;
	}
	
	private _setStateToCancelled(): void {
		this.state = EState.cancelled;
	}
	
	private _setStateToError(): void {
		this.state = EState.error;
	}
	
	
	public mounted(): void {
	
	}
}
