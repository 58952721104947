
import { Component, Vue } from "vue-property-decorator";
import { SvcApi } from "@/Service/Api/SvcApi";
import { IAccountInfo } from "@/Service/Account/IAccountInfo/IAccountInfo";
import { EZ } from "@/Utils/EZ/EZ";
import { WindowUtils } from "@/Utils/WindowUtils/WindowUtils";
import { Utils } from "@/Utils/Utils";

enum EState {
	prompt = "prompt",
	editing = "editing",
	saving = "saving",
	saved = "saved",
	error = "error",
}

@Component({
	props: {
		accountInfo: Object,
	},
})
export default class RegistrationBox extends Vue {
	private get _svcApi(): SvcApi { return SvcApi.i(); }
	
	public state: EState = EState.prompt;
	public email: string|null = null;
	public password: string|null = null;
	public errorMessage: string|null = null;
	
	private get _accountInfo(): IAccountInfo {
		return this["accountInfo"] as IAccountInfo;
	}
	
	get hasLoginCredentials(): boolean {
		return (this._accountInfo.loginEmail != null);
	}
	
	
	public get isStatePrompt(): boolean {
		return (this.state == EState.prompt);
	}
	
	public get isStateEditing(): boolean {
		return (this.state == EState.editing);
	}
	
	public get isStateSaving(): boolean {
		return (this.state == EState.saving);
	}
	
	public get isStateSaved(): boolean {
		return (this.state == EState.saved);
	}
	
	public get isStateError(): boolean {
		return (this.state == EState.error);
	}
	
	public get slackWorkspaceIdentifier(): string {
		return this._accountInfo.slackWorkspaceIdentifier;
	}

	public async save(): Promise<void> {
		if (!this.validate(false)) {
			return;
		}
		
		this._setStateToSaving();
		if (await this._svcApi.updateCredentials(this.email!, this.password!)) {
			this._setStateToSaved();
			return;
		}
		
		this._setStateToError();
	}
	
	public validate(fromBlur: boolean = true): boolean {
		let arrMessages: string[] = [ ];
		
		if (fromBlur) {
			if (this.email == null || this.password == null) {
				return true;
			}
		}
		
		if (!Utils.validateEmail(this.email)) {
			arrMessages.push("Please enter a valid email address.");
		}
		
		if (!Utils.validatePassword(this.password, { minLength: 8, maxLength: 20 })) {
			arrMessages.push("Please enter a password of 8 to 20 characters.");
		}
		
		if (arrMessages.length == 0) {
			this.errorMessage = null;
			return true;
		}
		
		this.errorMessage = arrMessages.join("<br/><br/>")
		
		return false;
	}
	
	public close(): void {
		this._setStateToPrompt();
	}
	
	public change(): void {
		this._setStateToEditing();
	}

	private _setStateToPrompt(): void {
		this.state = EState.prompt;
	}

	private _setStateToEditing(): void {
		this.state = EState.editing;
	}

	public setStateToEditing(): void {
		this.state = EState.editing;
	}
	
	private _setStateToSaving(): void {
		this.state = EState.saving;
	}
	
	private _setStateToSaved(): void {
		this.state = EState.saved;
	}
	
	private _setStateToError(): void {
		this.state = EState.error;
	}
	
	public mounted(): void {
		this.email = this._accountInfo.loginEmail;
	}
}
