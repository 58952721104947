
// mimic the stuff we need from JQueryPromise
export enum EDeferredAsyncState {
	pending = "pending",
	resolved = "resolved",
	rejected = "rejected",
}

export interface IDeferredAsync<T> {
	resolve: (item: T) => void;
	reject: (err?: any) => void;
	promise: Promise<T>;
	state: EDeferredAsyncState;
}

export class Q {
	public static deferAsync<T>(): IDeferredAsync<T> {
		let deferred: IDeferredAsync<T> = { } as IDeferredAsync<T>;
		deferred.state = EDeferredAsyncState.pending;
		let promise = new Promise<T>((resolve, reject) => {
			deferred.resolve = (result: T) => {
				deferred.state = EDeferredAsyncState.resolved;
				return resolve(result);
			};
			deferred.reject = (err) => {
				deferred.state = EDeferredAsyncState.rejected;
				return reject(err);
			};
		});
		deferred.promise = promise;
		return deferred;
	}
}
