
import { Component } from "vue-property-decorator";
import { Vue } from "vue-property-decorator";
import { SvcVendorSlack } from "@/Service/Vendors/Slack/SvcVendorSlack";
import { Utils } from "@/Utils/Utils";

@Component({
	props: {

	},
})
export default class AddToSlack extends Vue {
	private get _svcVendorSlack(): SvcVendorSlack { return SvcVendorSlack.i(); }

	public get addToSlackUrl(): string {
		// let base = "https://slack.com/oauth/v2/authorize?client_id=4607701396997.4604474765062&scope=app_mentions:read,chat:write,im:history&user_scope=";
		// let url = `${base}&state=development`;
		// return url;
		return this._svcVendorSlack.getAddToSlackUrl();
	}

	public mounted(): void {
	}
}
