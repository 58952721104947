import { render, staticRenderFns } from "./SelfOpenAIApiKeyBox.vue?vue&type=template&id=03060415&scoped=true&"
import script from "./SelfOpenAIApiKeyBox.vue?vue&type=script&lang=ts&"
export * from "./SelfOpenAIApiKeyBox.vue?vue&type=script&lang=ts&"
import style0 from "./SelfOpenAIApiKeyBox.vue?vue&type=style&index=0&id=03060415&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03060415",
  null
  
)

export default component.exports