
// import AppAnnouncement from '../components/announcement'
import NavBar from "@/views/Components/NavBar.vue";
import AddToSlack from "@/views/Components/AddToSlack.vue";
import { Vue } from "vue-property-decorator";
import { Component } from "vue-property-decorator";
import { Singleton } from "@/FW/Singleton/Singleton";

// export default {
// 	name: 'Home',
// 	components: {
// 		AppAnnouncement,
// 		// AppNavbar,
// 		NavBar,
// 		AddToSlack,
// 	},
//
// 	data() {
// 		return {
// 			rawbagn: ' ',
// 			rawcvrj: ' ',
// 			rawa5j2: ' ',
// 			raws7i9: ' ',
// 			rawdpl0: ' ',
// 			raw6i8p: ' ',
// 			raw7z6p: ' ',
// 			rawuw7r: ' ',
// 			rawfn1x: ' ',
// 			rawdxru: ' ',
// 			rawfyz7: ' ',
// 			rawjluw: ' ',
// 			rawm2tk: ' ',
// 		}
// 	},
//
// 	metaInfo: {
// 		title: 'Boboto',
// 		meta: [
// 			{
// 				name: 'description',
// 				content:
// 					'Boboto is the best way to leverage cutting edge AI chatbots at work!',
// 			},
// 			{
// 				property: 'og:title',
// 				content: 'Boboto',
// 			},
// 			{
// 				property: 'og:description',
// 				content:
// 					'Boboto is the best way to leverage cutting edge AI chatbots at work!',
// 			},
// 		],
// 	},
// }
@Component({
	components: {
		// AppAnnouncement,
		NavBar,
		AddToSlack,
	},
	metaInfo: {
		title: 'Boboto',
		meta: [
			{
				name: 'description',
				content: 'Boboto is the best way to leverage cutting edge AI chatbots on Slack!',
			},
			{
				property: 'og:title',
				content: 'Boboto',
			},
			{
				property: 'og:description',
				content: 'Boboto is the best way to leverage cutting edge AI chatbots on Slack!',
			},
		],
	},
})
export default class Home extends Vue {
	public isReady: boolean = false;
	
	public mounted(): void {
		Singleton.init();
		this.isReady = true;
	}
}
