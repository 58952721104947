
import { Component, Vue } from "vue-property-decorator";
import AppNavbar from '../../components/navbar.vue';
import { NoConcurrency } from "@/Utils/NoConcurrency/NoConcurrency";
import { Utils } from "@/Utils/Utils";
import { SvcApi } from "@/Service/Api/SvcApi";
import { SvcBootstrap } from "@/Service/Bootstrap/SvcBootstrap";
import { Singleton } from "@/FW/Singleton/Singleton";
import { WindowUtils } from "@/Utils/WindowUtils/WindowUtils";
import { SvcStorage } from "@/Service/Storage/SvcStorage";
import { IGetAccountInfoResponse } from "@/Service/Api/GetAccountInfo/IGetAccountInfoResponse";
import { IAccountInfo } from "@/Service/Account/IAccountInfo/IAccountInfo";
import TrialScreen from "@/views/Member/TrialScreen/TrialScreen.vue";
import { SvcVendorPaypal } from "@/Service/Vendors/Paypal/SvcVendorPaypal";
import ActivePlanScreen from "@/views/Member/ActivePlanScreen/ActivePlanScreen.vue";
import RegistrationBox from "@/views/Member/RegistrationBox/RegistrationBox.vue";
import NavBar from "@/views/Components/NavBar.vue";

@Component({
	components: {
		NavBar,
		TrialScreen,
		ActivePlanScreen,
		RegistrationBox,
		//AppNavbar,
	},
	metaInfo: {
		title: "Member - Boboto",
		meta: [
			{
				name: "description",
				content:
					"Boboto is the best way to leverage cutting edge AI chatbots on Slack!",
			},
			{
				property: "og:title",
				content: "Login - Boboto",
			},
			{
				property: "og:description",
				content: "Boboto is the best way to leverage cutting edge AI chatbots on Slack!",
			},
		],
	},
})

export default class Member extends Vue {
	private static readonly QS_LOGIN_TOKEN = "loginToken";
	
	private get _svcApi(): SvcApi { return SvcApi.i(); }
	private get _svcBootstrap(): SvcBootstrap { return SvcBootstrap.i(); }
	private get _svcStorage(): SvcStorage { return SvcStorage.i(); }
	private get _svcVendorPaypal(): SvcVendorPaypal { return SvcVendorPaypal.i(); }
	
	//noconLogin: NoConcurrency = new NoConcurrency();
	public isReady: boolean = false;
	public stupidReactivityCounter: number = 0;
	
	public accountInfo: IAccountInfo = null as any;
	
	// public login(): void {
	// 	this.noconLogin.go(async () => {
	// 		// let { data, status } = await axios.post("https://api.boboto.chat/Prod/dispatcher/?route=/login", {
	// 		// 	email: "blah",
	// 		// 	password: "blah",
	// 		// });
	// 		//let { data, status } = await axios.get("https://api.boboto.chat/Prod/dispatcher/?route=/login");
	// 		// let { data, status } = await axios.get("https://api.boboto.chat/Prod/dispatcher/?route=/hello");
	// 		// let { data, status } = await axios.post("https://api.boboto.chat/Prod/dispatcher/?route=/login");
	// 		let { data, status } = await axios.post("https://api.boboto.chat/Prod/dispatcher/?route=/hello", {
	// 			email: "blah",
	// 			password: "blah",
	// 		});
	// 		console.log("status: ", status, "data: ", data);
	// 	});
	// }
	
	get hasActiveSubscription(): boolean {
		if (this.stupidReactivityCounter == 88.8) {
			return false;
		}
		
		return this.accountInfo.hasActiveSubscription;
	}
	
	get hasLingeringSubscription(): boolean {
		if (this.stupidReactivityCounter == 88.8) {
			return false;
		}
		
		return this.accountInfo.hasLingeringSubscription;
	}
	
	public async onSubscribed(): Promise<void> {
		this.isReady = false;
// alert('here 1');
		if (!(await this._tryReloadAccountInfo())) {
// alert('here 2');
			WindowUtils.refresh();
			return;
		}
// alert('here 3');
		this.stupidReactivityCounter++;
		
		this.isReady = true;
	}
	
	public mounted(): void {
		this._bootstrap();
		this._onAttachedAsync();
	}
	
	private _bootstrap(): void {
		Singleton.init();
		this._svcVendorPaypal.init();
		this._svcBootstrap.go();
	}
	
	private async _onAttachedAsync(): Promise<void> {
		if (await this._loginIfNeeded()) {
			WindowUtils.redirect("/member");
			return;
		}
		
		if (!this._tryApplySession()) {
			WindowUtils.redirect("/login");
		}
		
		if (!(await this._tryReloadAccountInfo())) {
			// an error occurred and the handling code will probably redirect soon
			return;
		}
		
		this.isReady = true;
	}
	
	private async _tryReloadAccountInfo(): Promise<boolean> {
		let result = await this._tryGetAccountInfo();
		if (result == null) {
			// an error occurred and the handling code will probably redirect soon
			return false;
		}
		
		this.accountInfo = result.data!;
		
		return true;
	}
	
	private async _loginIfNeeded(): Promise<boolean> {
//debugger;
		let loginToken = this._tryGetQSLoginToken();
		if (loginToken == null) {
			return false;
		}
		
		let response = await this._svcApi.loginWithToken(loginToken);
		if (response.errorCode != null) {
			alert("Login token expired or was already used. Please create a new login link.");
			WindowUtils.redirect("/login");
		}
		
		this._storeSession({
			sessionIdentifier: response.data!.sessionIdentifier!,
			workspaceName: response.data!.slackWorkspaceName!,
		});
		
		return true;
	}
	
	private _storeSession(args: { sessionIdentifier: string, workspaceName: string }): void {
		this._svcStorage.storeSession(args);
	}
	
	private _tryGetQSLoginToken(): string|null {
		let loginToken = Utils.getQueryStringParameter(Member.QS_LOGIN_TOKEN);
		return loginToken;
	}
	
	private _tryApplySession(): boolean {
		let sessionIdentifier = this._svcStorage.getSessionIdentifier()!;
		if (sessionIdentifier == null) {
			return false;
		}
		
		this._svcApi.applySession(sessionIdentifier);
		return true;
	}
	
	private async _tryGetAccountInfo(): Promise<IGetAccountInfoResponse|null> {
		let accountInfo = await this._svcApi.getAccountInfo();
		// EzError.assert(accountInfo != null, "_getAccountInfo(): received null account info");
		
		return accountInfo!;
	}
}
