import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import Member from './views/Member/Member'
import PrivacyPolicy from './views/PrivacyPolicy/PrivacyPolicy'
import Tos from './views/Tos/Tos'
import Pricing from './views/Pricing/Pricing'
import Contact from './views/Contact/Contact'
import Login from './views/login'
import Home from './views/home'
import './style.css'
import 'vuetify/dist/vuetify.min.css' // https://v15.vuetifyjs.com/en/getting-started/quick-start/ , Ensure you are using css-loader

Vue.use(Router)
Vue.use(Meta)
export default new Router({
  mode: 'history',
  routes: [
    {
      name: 'Member',
      path: '/member',
      component: Member,
    },
    {
      name: 'PrivacyPolicy',
      path: '/privacy-policy',
      component: PrivacyPolicy,
    },
    {
      name: 'Tos',
      path: '/tos',
      component: Tos,
    },
    {
      name: 'Pricing',
      path: '/pricing',
      component: Pricing,
    },
    {
      name: 'Contact',
      path: '/contact',
      component: Contact,
    },
    {
      name: 'Login',
      path: '/login',
      component: Login,
    },
    {
      name: 'Home',
      path: '/',
      component: Home,
    },
  ],
})
