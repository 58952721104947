
import { Component, Vue } from "vue-property-decorator";
import { SvcApi } from "@/Service/Api/SvcApi";
import { IAccountInfo } from "@/Service/Account/IAccountInfo/IAccountInfo";
import { EZ } from "@/Utils/EZ/EZ";
import { WindowUtils } from "@/Utils/WindowUtils/WindowUtils";

enum EState {
	prompt = "prompt",
	saving = "saving",
	saved = "saved",
	error = "error",
}

@Component({
	props: {
		accountInfo: Object,
	},
})
export default class SelfOpenAIApiKeyBox extends Vue {
	private get _svcApi(): SvcApi { return SvcApi.i(); }
	
	public state: EState = EState.prompt;
	public newOpenAIApiKey: string|null = null;
	
	private _wasChanged: boolean = false;
	
	private get _accountInfo(): IAccountInfo {
		return this["accountInfo"] as IAccountInfo;
	}
	
	public get isStatePrompt(): boolean {
		return (this.state == EState.prompt);
	}
	
	public get isStateSaving(): boolean {
		return (this.state == EState.saving);
	}
	
	public get isStateSaved(): boolean {
		return (this.state == EState.saved);
	}
	
	public get isStateError(): boolean {
		return (this.state == EState.error);
	}
	
	public get isUsingOwnOpenAIAccount(): boolean {
		return this._accountInfo.isUsingOwnOpenAIAccount;
	}
	
	public get slackWorkspaceIdentifier(): string {
		return this._accountInfo.slackWorkspaceIdentifier;
	}

	public get openAIApiKeySuffix(): string {
		return this._accountInfo.openAIApiKeySuffix!;
	}

	public get doesRequireSelfOpenAIKeyToUse(): boolean {
		return this._accountInfo.doesRequireSelfOpenAIKeyToUse!;
	}
	
	public async saveNewOpenAIApiKey(): Promise<void> {
		await this._applyOpenAIApiKey();
	}
	
	public async clearOpenAIApiKey(): Promise<void> {
		this.newOpenAIApiKey = null;
		await this._applyOpenAIApiKey();
	}
	
	public closeSetOpenAIApiKey(): void {
		this.$emit("close", this._wasChanged);
	}
	
	private async _applyOpenAIApiKey(): Promise<void> {
		let key = (this.newOpenAIApiKey == "" ? null : this.newOpenAIApiKey);
		this._setStateToSaving();
		if (await this._svcApi.applyOpenAIApiKey(key)) {
			this._wasChanged = true;
			this._setStateToSaved();
			return;
		}
		
		this._setStateToError();
	}

	private _setStateToPrompt(): void {
		this.state = EState.prompt;
	}
	
	private _setStateToSaving(): void {
		this.state = EState.saving;
	}
	
	private _setStateToSaved(): void {
		this.state = EState.saved;
	}
	
	private _setStateToError(): void {
		this.state = EState.error;
	}
	
	public mounted(): void {
	
	}
}
