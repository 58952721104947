import { ServiceBase } from "@/Service/ServiceBase";
import { loadScript } from "@paypal/paypal-js";
import { Cfg } from "@/Cfg/Cfg";
import { PayPalNamespace } from "@paypal/paypal-js";
import { OnApproveData } from "@paypal/paypal-js/types/components/buttons";
import { OnApproveActions } from "@paypal/paypal-js/types/components/buttons";
import { OnCancelledActions } from "@paypal/paypal-js/types/components/buttons";
import { CreateSubscriptionActions } from "@paypal/paypal-js/types/components/buttons";
import { CreateSubscriptionRequestBody } from "@paypal/paypal-js/types/apis/subscriptions/subscriptions";
import { EZ } from "@/Utils/EZ/EZ";
import { PayPalButtonsComponent } from "@paypal/paypal-js/types/components/buttons";
import { IPaypalCfg } from "@/Cfg/Cfg";
import { OnClickActions } from "@paypal/paypal-js/types/components/buttons";
import { Utils } from "@/Utils/Utils";

export class SvcVendorPaypal extends ServiceBase {
	private _paypalPromise!: Promise<PayPalNamespace|null>;
	private _planIdentifier!: string;

	private async _paypal(): Promise<PayPalNamespace|null> {
		return await this._paypalPromise;
	}

	public init(): void {
		let cfg = this.getPaypalCfg();
		this._paypalPromise = loadScript({
			"client-id": cfg.paypalClientIdentifier,
			vault: true,
			intent: "subscription",
		});
	}

	//private _getPaypalCfg(forLive: boolean = false): IPaypalCfg {
	public getPaypalCfg(): IPaypalCfg {
		let isLocal = Utils.isLocal();
		return (isLocal ? Cfg.paypal.sandbox : Cfg.paypal.live);
	}

	public async initButtons(args: {
		elContainer: HTMLElement,
		planIdentifier: string,
		funcGetQuantity: () => number,
		funcOnApprove: (data: OnApproveData, actions: OnApproveActions) => Promise<void>,
		funcOnCancel: (data: Record<string, unknown>, actions: OnCancelledActions) => void,
		funcOnError: (err: Record<string, unknown>) => void,
		funcOnClick: (data: Record<string, unknown>) => Promise<void>,
	}): Promise<void> {
		let paypal = await this._paypal();
		if (paypal == null) {
			console.warn("Failed to initialize Paypal");
			return;
		}

		this._planIdentifier = args.planIdentifier;
		let buttons: PayPalButtonsComponent = await paypal.Buttons!({
			style: {
				shape: "rect",
				color: "gold",
				layout: "vertical",
				label: "paypal"
			},
			createSubscription: async (data: Record<string, unknown>, actions: CreateSubscriptionActions): Promise<string> => {
				let quantity = args.funcGetQuantity();
				let options: CreateSubscriptionRequestBody = {
					plan_id: this._planIdentifier,
					quantity: EZ.stringify(quantity)!,
					auto_renewal: true,
				};

				let subscriptionIdentifier = await actions.subscription.create(options);
				return subscriptionIdentifier;
			},
			onApprove: async (data: OnApproveData, actions: OnApproveActions): Promise<void> => {
				await args.funcOnApprove(data, actions);
			},
			onCancel: (data: Record<string, unknown>, actions: OnCancelledActions): void => {
				args.funcOnCancel(data, actions);
			},
			onError: (data: Record<string, unknown>): void => {
				args.funcOnError(data);
			},
			onClick: async (data: Record<string, unknown>, actions: OnClickActions): Promise<void> => {
				await args.funcOnClick(data);
			},
		});

		//.render('#paypal-button-container-P-6GB451724H817293KMPJRYAQ'); // Renders the PayPal button
		await buttons.render(args.elContainer);
	}

	public setPlanIdentifier(planIdentifier: string): void {
		this._planIdentifier = planIdentifier;
	}
}
