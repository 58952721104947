export interface IGuid {
	getGuid(): number;
	getGuidAsString(): string;
}

export class Guid implements IGuid {
	private static _nextGuid: number = 1;
	private readonly __guid: number;
	constructor() {
		this.__guid = Guid.makeGloballyUniqueGuid();
	}
	public get _guid(): number { return this.__guid; }
	public static makeGloballyUniqueGuid(): number {
		let guid: number = Guid._nextGuid++;
// if (guid == 190) {
// 	debugger;
// }
		return guid;
	}
	public getGuid(): number {
		return this._guid;
	}
	public getGuidAsString(): string {
		return this.getGuid().toString();
	}
}
