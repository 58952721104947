import { ServiceBase } from "@/Service/ServiceBase";
import { SvcAjax } from "@/Service/Ajax/SvcAjax";
import { ILoginWithTokenRequest } from "@/Service/Api/LoginWithToken/ILoginWithTokenRequest";
import { ILoginWithTokenResponse } from "@/Service/Api/LoginWithToken/ILoginWithTokenResponse";
import { IGetAccountInfoResponse } from "@/Service/Api/GetAccountInfo/IGetAccountInfoResponse";
import { IGetAccountInfoRequest } from "@/Service/Api/GetAccountInfo/IGetAccountInfoRequest";
import { EzError } from "@/FW/Error/EzError";
import { IResponseBase } from "@/Service/Api/ResponseBase/IResponseBase";
import { EClientErrorCode } from "@/Service/Error/EClientErrorCode/EClientErrorCode";
import { WindowUtils } from "@/Utils/WindowUtils/WindowUtils";
import { IApplyOpenAIApiKeyRequest } from "@/Service/Api/ApplyOpenAIApiKey/IApplyOpenAIApiKeyRequest";
import { IApplyOpenAIApiKeyResponse } from "@/Service/Api/ApplyOpenAIApiKey/IApplyOpenAIApiKeyResponse";
import { IUpdateCredentialsRequest } from "@/Service/Api/UpdateCredentials/IUpdateCredentialsRequest";
import { IUpdateCredentialsResponse } from "@/Service/Api/UpdateCredentials/IUpdateCredentialsResponse";
import { IFinalizePaypalSubscriptionRequest } from "@/Service/Api/FinalizePaypalSubscription/IFinalizePaypalSubscriptionRequest";
import { IFinalizePaypalSubscriptionResponse } from "@/Service/Api/FinalizePaypalSubscription/IFinalizePaypalSubscriptionResponse";
import { ICancelPaypalSubscriptionRequest } from "@/Service/Api/CancelPaypalSubscription/ICancelPaypalSubscriptionRequest";
import { ICancelPaypalSubscriptionResponse } from "@/Service/Api/CancelPaypalSubscription/ICancelPaypalSubscriptionResponse";
import { IRevisePaypalSubscriptionRequest } from "@/Service/Api/RevisePaypalSubscription/IRevisePaypalSubscriptionRequest";
import { IRevisePaypalSubscriptionResponse } from "@/Service/Api/RevisePaypalSubscription/IRevisePaypalSubscriptionResponse";
import { ILoginWithCredentialsRequest } from "@/Service/Api/LoginWithCredentials/ILoginWithCredentialsRequest";
import { ILoginWithCredentialsResponse } from "@/Service/Api/LoginWithCredentials/ILoginWithCredentialsResponse";

enum ERoute {
	applyOpenAIApiKey = "applyOpenAIApiKey",
	cancelPaypalSubscription = "cancelPaypalSubscription",
	finalizePaypalSubscription = "finalizePaypalSubscription",
	getAccountInfo = "getAccountInfo",
	loginWithToken = "loginWithToken",
	loginWithCredentials = "loginWithCredentials",
	revisePaypalSubscription = "revisePaypalSubscription",
	updateCredentials = "updateCredentials",
}

export class SvcApi extends ServiceBase {
	private get _svcAjax(): SvcAjax { return SvcAjax.i(); }

	private static readonly API_ENDPOINT = "https://api.boboto.chat/Prod/dispatcher";

	private _sessionIdentifier: string|null = null;

	public applySession(sessionIdentifier: string|null): void {
		this._sessionIdentifier = sessionIdentifier;
	}

	public async loginWithToken(token: string): Promise<ILoginWithTokenResponse> {
		let request: ILoginWithTokenRequest = { token };
		let response: ILoginWithTokenResponse = await this._post(ERoute.loginWithToken, request);
		return response;
	}

	public async loginWithCredentials(email: string, password: string): Promise<ILoginWithTokenResponse> {
		let request: ILoginWithCredentialsRequest = { email, password };
		let response: ILoginWithCredentialsResponse = await this._post(ERoute.loginWithCredentials, request);
		return response;
	}

	public async getAccountInfo(): Promise<IGetAccountInfoResponse|null> {
		EzError.assert(this._sessionIdentifier != null, "SvcApi._sessionIdentifier is null");

		let request: IGetAccountInfoRequest = { sessionIdentifier: this._sessionIdentifier! };
		let response: IGetAccountInfoResponse = await this._post(ERoute.getAccountInfo, request);
		if (this._handleErrorIfNecessary(response)) {
			return null;
		}

		return response;
	}

	public async finalizePaypalSubscription(subscriptionIdentifier: string): Promise<IFinalizePaypalSubscriptionResponse|null> {
		EzError.assert(this._sessionIdentifier != null, "SvcApi._sessionIdentifier is null");

		let request: IFinalizePaypalSubscriptionRequest = {
			sessionIdentifier: this._sessionIdentifier!,
			subscriptionIdentifier,
		};
		let response: IFinalizePaypalSubscriptionResponse;

		try {
			response = await this._post(ERoute.finalizePaypalSubscription, request);
		}
		catch (ex) {
			console.warn("An exception has occurred: ", ex);
			return null;
		}

		if (this._handleErrorIfNecessary(response)) {
			return null;
		}

		return response;
	}

	public async cancelPaypalSubscription(): Promise<boolean> {
		EzError.assert(this._sessionIdentifier != null, "SvcApi._sessionIdentifier is null");

		let request: ICancelPaypalSubscriptionRequest = {
			sessionIdentifier: this._sessionIdentifier!,
		};
		let response: ICancelPaypalSubscriptionResponse;

		try {
			response = await this._post(ERoute.cancelPaypalSubscription, request);
		}
		catch (ex) {
			console.warn("An exception has occurred: ", ex);
			return false;
		}

		if (this._handleErrorIfNecessary(response)) {
			return false;
		}

		return true;
	}

	public async revisePaypalSubscription(newQuantity: number): Promise<boolean> {
		EzError.assert(this._sessionIdentifier != null, "SvcApi._sessionIdentifier is null");

		let request: IRevisePaypalSubscriptionRequest = {
			sessionIdentifier: this._sessionIdentifier!,
			newQuantity,
		};
		let response: IRevisePaypalSubscriptionResponse;

		try {
			response = await this._post(ERoute.revisePaypalSubscription, request);
		}
		catch (ex) {
			console.warn("An exception has occurred: ", ex);
			return false;
		}

		if (this._handleErrorIfNecessary(response)) {
			return false;
		}

		return true;
	}

	public async applyOpenAIApiKey(key: string|null): Promise<boolean> {
		EzError.assert(this._sessionIdentifier != null, "SvcApi._sessionIdentifier is null");

		let request: IApplyOpenAIApiKeyRequest = {
			sessionIdentifier: this._sessionIdentifier!,
			openAIApiKey: key,
		};
		let response: IApplyOpenAIApiKeyResponse;

		try {
			response = await this._post(ERoute.applyOpenAIApiKey, request);
		}
		catch (ex) {
			console.warn("An exception has occurred: ", ex);
			return false;
		}

		if (this._handleErrorIfNecessary(response)) {
			return false;
		}

		return true;
	}

	public async updateCredentials(email: string, password: string): Promise<boolean> {
		EzError.assert(this._sessionIdentifier != null, "SvcApi._sessionIdentifier is null");

		let request: IUpdateCredentialsRequest = {
			sessionIdentifier: this._sessionIdentifier!,
			email,
			password,
		};
		let response: IUpdateCredentialsResponse;

		try {
			response = await this._post(ERoute.updateCredentials, request);
		}
		catch (ex) {
			console.warn("An exception has occurred: ", ex);
			return false;
		}

		if (this._handleErrorIfNecessary(response)) {
			return false;
		}

		return true;
	}

	private async _post<TResponse, TRequest = Object>(route: ERoute, requestData: TRequest): Promise<TResponse> {
		let url = this._makeUrl(route);
		let response: TResponse = await this._svcAjax.post<TResponse>(url, requestData);
		return response;
	}

	private _makeUrl(route: ERoute): string {
		let endpoint = SvcApi.API_ENDPOINT;
		let url = `${endpoint}/?route=/${route}`;
		return url;
	}

	private _handleErrorIfNecessary(response: IResponseBase): boolean {
		if (response.success) {
			return false;
		}

		if (response.errorCode == EClientErrorCode.invalidSessionIdentifier) {
			alert("Your session has expired. Please log in again.");
			WindowUtils.redirect("/login");
			return true;
		}

		alert(`An error has occurred: ${response.errorMessage} (${response.errorCode})`);
		return true;
	}
}
